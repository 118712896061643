// TODO check import error during compilation
// "services" is not exported by "../../frameworks/waterworks-mixed-types/dist/index.js", imported by "src/models/auth/authz.schema.ts".
// import { services } from '@fountain/waterworks-mixed-types';

// export const AuthzMiddlewareError =
//   services.authorization.middlewares.AuthzMiddlewareError;

export enum AuthzMiddlewareError {
  ActionKeyNotAllowed = '#f148d1ca',
  LocationsMismatch = '#c62f069b',
}
