import { cx } from '@/themes/utils/cssUtils';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

interface UiBoxContainerProps extends PropsWithChildren {
  className?: string;
  contentClassName?: string;
  header?: ReactNode;
  noHeaderPadding?: boolean;
  noHeaderBgColor?: boolean;
  noBgColor?: boolean;
  hasPadding?: boolean;
  hasShadow?: boolean;
  title?: ReactNode;
  mobile?: boolean;
}

function UiBoxContainer({
  className,
  contentClassName,
  header,
  title,
  children,
  noHeaderPadding,
  noHeaderBgColor,
  noBgColor = false,
  hasPadding = false,
  hasShadow = true,
  mobile,
}: UiBoxContainerProps) {
  return (
    <div
      className={cx('overflow-auto rounded-lg', className, {
        'shadow-md': hasShadow,
      })}
    >
      {title}
      {header && (
        <Header
          padding={noHeaderPadding ? 'none' : undefined}
          color={noHeaderBgColor ? 'transparent' : undefined}
          mobile={mobile}
        >
          {header}
        </Header>
      )}
      <div
        className={cx('flex-grow rounded-b-lg', contentClassName, {
          'bg-white': !noBgColor,
          'p-8': hasPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
}

const header = tv({
  base: 'bg-gradient-wx-neutral-50 h-12 overflow-x-auto overflow-y-hidden rounded-t-lg border-b px-8',
  variants: {
    color: {
      transparent: 'bg-transparent',
    },
    mobile: {
      true: 'h-auto border-none px-0',
    },
    padding: {
      none: 'p-0',
    },
  },
  defaultVariants: {
    mobile: false,
  },
});

type HeaderVariants = VariantProps<typeof header>;

interface HeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'color'>,
    PropsWithChildren,
    HeaderVariants {}

function Header({
  children,
  className,
  color,
  padding,
  mobile,
  ...props
}: HeaderProps) {
  const tv = header({
    color,
    padding,
    mobile,
  });

  return (
    <div className={cx(tv, className)} {...props}>
      {children}
    </div>
  );
}

UiBoxContainer.Header = Header;

export default UiBoxContainer;
