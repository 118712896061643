export const EMPLOYER_BASE_URL = import.meta.env.VITE_EMPLOYER_BASE_URL || '';
export const EMPLOYER_BASE_PATH = import.meta.env.VITE_EMPLOYER_BASE_PATH || '';
export const SECURITY_SERVICE_BASE_URL =
  import.meta.env.VITE_SECURITY_SERVICE_BASE_URL || 'http://localhost:8107';

export const MAX_CUSTOM_ATTRIBUTE_LABEL_SIZE = 200;
export const MAX_COMPANY_NAME_SIZE = 100;

export const DRAWER_WIDTH_OPEN = 256;
export const DRAWER_WIDTH_CLOSED = 72;
export const DRAWER_BG_COLOR = '#fff';

export enum SidebarMode {
  Open,
  Closed,
  Hidden,
}

export const LoginMethod = {
  Password: 'password',
  Uuid: 'uuid',
  Token: 'token',
} as const;

export type LoginMethodType = (typeof LoginMethod)[keyof typeof LoginMethod];

export const AuthStatus = {
  Authenticated: 'authenticated',
  Unauthenticated: 'unauthenticated',
} as const;

export type AuthStatusType = (typeof AuthStatus)[keyof typeof AuthStatus];

export const AuthError = {
  InvalidCredentials: 'invalid-credentials',
  Locked: 'locked',
  Generic: 'generic',
} as const;

export type AuthErrorType = (typeof AuthError)[keyof typeof AuthError];
