/* eslint-disable @typescript-eslint/no-explicit-any */

import ErrorPage from '@/components/pages/_shared/ErrorPage';
import UiLoaderFullPage from '@/components/ui/LoaderFullPage';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { makeRoutes } from './utils/routeUtils';
import { makeTree, orderTree } from './utils/treeUtils';
import { EMPLOYER_BASE_PATH } from '@/utils/employer/constants';

interface Route {
  path?: string;
  children?: Route[];
  level?: number;
  fullPath?: string;
  element?: any;
}

function processPathEmployer(path: string) {
  return path
    .replace(/\/src\/app\/employer|\.tsx$/g, '')
    .replace(/\[([^/]+)\]/g, ':$1');
}

/**
 * Configures a route by updating its properties and adding fallback and error elements.
 * @param route - The route to configure.
 * @param level - The level of the route in the route hierarchy.
 * @param path - The path of the current route.
 * @returns The configured route.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function configureRouteEmployer(route: Route, level = 0, path = ''): any {
  if (level === 0) {
    if (route.path === 'layout' && route.element && route.children) {
      return {
        ...route,
        element: (
          <Suspense fallback={<UiLoaderFullPage />}>
            <route.element>
              <Outlet />
            </route.element>
          </Suspense>
        ),
        path: '/',
        errorElement: <ErrorPage />,
        children: [
          ...route.children,
          {
            index: true,
            element: <Navigate to={`${EMPLOYER_BASE_PATH}/workers`} />,
          },
        ],
      };
    }
  }

  if (route.path === 'layout') {
    delete route.path;

    return {
      ...route,
      element: (
        <route.element>
          <Outlet />
        </route.element>
      ),
      errorElement: <ErrorPage />,
    };
  }

  if (route.path === 'page') {
    delete route.path;

    return {
      ...route,
      index: true,
      element: <route.element />,
      errorElement: <ErrorPage />,
    };
  }

  // Handles route segments with parenthesis (optional routes)
  if (route.path && /^\(.*\)$/.test(route.path)) {
    delete route.path;

    return {
      ...route,
    };
  }

  return route;
}

const employerRoutes = import.meta.glob(
  '/src/app/employer/**/(layout|page).tsx',
  {
    eager: false,
  },
);

const tree = makeTree(employerRoutes, processPathEmployer);
const orderedTree = orderTree(tree);
const routes = makeRoutes(orderedTree, 0, '', configureRouteEmployer);

export default routes;
