import { MuiBackdrop } from '@/components/mui/Backdrop';
import { MuiCircularProgress } from '@/components/mui/CircularProgress';
import colors from '@/themes/tokens/colors';

export interface UiLoaderFullPageProps {
  active?: boolean;
  className?: string;
}

export default function UiLoaderFullPage({
  active = true,
  className,
}: UiLoaderFullPageProps) {
  return (
    <MuiBackdrop
      sx={{
        color: colors.wx.brand[400],
        backgroundColor: colors.wx.neutral[200],
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      className={className}
      open={active}
    >
      <MuiCircularProgress color="inherit" />
    </MuiBackdrop>
  );
}
