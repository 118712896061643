import { MuiAlert } from '@/components/mui/Alert';
import { MuiButton } from '@/components/mui/Button';
import { MuiTypography } from '@/components/mui/Typography';
import SkeletonPageContainerWithBreadcrumb from '@/components/skeleton/PageContainerWithBreadcrumb';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

export default function UnauthorizedPage({
  roleRestricted,
}: {
  roleRestricted?: boolean;
}) {
  const navigate = useNavigate();

  if (roleRestricted) {
    return (
      <div>
        <MuiAlert
          severity="warning"
          action={
            <MuiButton color="warning" onClick={() => navigate(-1)}>
              <FormattedMessage defaultMessage="Go back" />
            </MuiButton>
          }
        >
          <FormattedMessage
            defaultMessage="It seems you don't have access to this page because of restrictions associated with your role."
            tagName="p"
          />
          <FormattedMessage
            defaultMessage="Please contact your administrator if you think you should have access."
            tagName="p"
          />
        </MuiAlert>
        <div className="blur-sm">
          <SkeletonPageContainerWithBreadcrumb />
        </div>
      </div>
    );
  }

  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <MuiTypography className="mb-4 text-6xl font-extrabold tracking-tight text-wx-warning-dark">
            <FormattedMessage defaultMessage="Unauthorized" />
          </MuiTypography>
          <p className="mb-4 text-3xl font-medium tracking-tight text-wx-neutral-900 md:text-4xl">
            <FormattedMessage defaultMessage="You are not authorized to access this resource." />
          </p>
          <p className="mb-4 text-lg text-wx-neutral-500">
            <FormattedMessage defaultMessage="We apologize for the inconvenience." />
          </p>
          <Link
            to="/"
            className="bg-wx-primary-600 hover:bg-wx-primary-800 my-4 inline-flex rounded-lg px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4 focus:ring-wx-brand-300"
          >
            <FormattedMessage defaultMessage="Back to Homepage" />
          </Link>
        </div>
      </div>
    </section>
  );
}
