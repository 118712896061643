import MuiSkeleton from '@/components/mui/Skeleton';
import UiBoxContainer from '@/components/ui/BoxContainer';

export default function SkeletonPageContainerWithBreadcrumb() {
  return (
    <div className="flex h-full w-full flex-col gap-4 px-6 py-4 sm:container sm:mx-auto">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <MuiSkeleton variant="circular" height="36px" width="36px" />

          <div className="flex items-end gap-2">
            <MuiSkeleton normalized className="h-7 w-32" />
            <MuiSkeleton normalized className="h-6 w-64" />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <MuiSkeleton normalized className="h-10 w-48" />
          <MuiSkeleton normalized className="h-10 w-32" />
        </div>
      </div>

      <div className="h-full">
        <UiBoxContainer
          className="rounded-lg"
          hasPadding={true}
          hasShadow={false}
        >
          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-between gap-2">
              <MuiSkeleton normalized className="h-5 w-1/6" />
              <MuiSkeleton normalized className="h-7 w-1/4" />
              <MuiSkeleton normalized className="h-5 w-1/6" />
            </div>
            <div className="flex flex-col gap-5">
              <MuiSkeleton normalized className="h-6" />
              <MuiSkeleton normalized className="h-6" />
              <MuiSkeleton normalized className="h-6" />
              <MuiSkeleton normalized className="h-6" />
              <MuiSkeleton normalized className="h-6" />
            </div>
            <div className="flex justify-end">
              <MuiSkeleton normalized className="h-7 w-1/2" />
            </div>
          </div>
        </UiBoxContainer>
      </div>
    </div>
  );
}
