export default function IconWorkerExperience() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6382 24.1043C29.6382 26.6984 27.5521 28.8 24.9771 28.8H10.0636C7.48857 28.8 5.40246 26.6984 5.40246 24.1043C5.40246 21.5102 7.48857 19.4086 10.0636 19.4086C13.3388 19.4086 15.7564 21.8337 16.8585 25.9826H18.1822C19.2843 21.8337 21.7019 19.4086 24.9771 19.4086C27.5521 19.4086 29.6382 21.5102 29.6382 24.1043Z"
        fill="url(#paint0_linear_3556_6771)"
      />
      <path
        d="M18.1822 13.7739C19.2843 9.62499 21.7019 7.19995 24.9771 7.19995C27.5521 7.19995 29.6382 9.30152 29.6382 11.8956C29.6382 14.4897 27.5521 16.5913 24.9771 16.5913C21.8656 16.5913 19.305 18.0626 17.5193 20.6733C15.7336 18.0626 13.1731 16.5913 10.0615 16.5913C7.4865 16.5913 5.40039 14.4876 5.40039 11.8956C5.40039 9.3036 7.4865 7.19995 10.0636 7.19995C13.3388 7.19995 15.7564 9.62499 16.8585 13.7739H18.1822Z"
        fill="url(#paint1_linear_3556_6771)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3556_6771"
          x1="17.5193"
          y1="7.19995"
          x2="17.5193"
          y2="28.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6696FF" />
          <stop offset="1" stopColor="#145AF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3556_6771"
          x1="17.5193"
          y1="7.19995"
          x2="17.5193"
          y2="28.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6696FF" />
          <stop offset="1" stopColor="#145AF5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

// This version doesn't have the padding in the SVG
export function IconWorkerExperienceVariant() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
    >
      <path
        d="M24.2378 17.1043C24.2378 19.6984 22.1517 21.8 19.5767 21.8H4.6632C2.08818 21.8 0.00207161 19.6984 0.00207161 17.1043C0.00207161 14.5102 2.08818 12.4086 4.6632 12.4086C7.93841 12.4086 10.356 14.8337 11.4581 18.9826H12.7818C13.8839 14.8337 16.3015 12.4086 19.5767 12.4086C22.1517 12.4086 24.2378 14.5102 24.2378 17.1043Z"
        fill="url(#paint0_linear_29622_2101)"
      />
      <path
        d="M12.7818 6.77386C13.8839 2.62499 16.3015 0.199951 19.5767 0.199951C22.1517 0.199951 24.2378 2.30152 24.2378 4.8956C24.2378 7.48969 22.1517 9.59125 19.5767 9.59125C16.4652 9.59125 13.9047 11.0626 12.1189 13.6733C10.3332 11.0626 7.77268 9.59125 4.66112 9.59125C2.08611 9.59125 0 7.4876 0 4.8956C0 2.3036 2.08611 0.199951 4.6632 0.199951C7.93841 0.199951 10.356 2.62499 11.4581 6.77386H12.7818Z"
        fill="url(#paint1_linear_29622_2101)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_29622_2101"
          x1="12.1189"
          y1="0.199951"
          x2="12.1189"
          y2="21.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6696FF" />
          <stop offset="1" stopColor="#145AF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_29622_2101"
          x1="12.1189"
          y1="0.199951"
          x2="12.1189"
          y2="21.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6696FF" />
          <stop offset="1" stopColor="#145AF5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
