import { datadogRum } from '@datadog/browser-rum';

export function initDatadogRum() {
  const {
    VITE_DATADOG_RUM_APPLICATION_ID,
    VITE_DATADOG_RUM_CLIENT_TOKEN,
    VITE_STAGE,
  } = import.meta.env;

  if (
    VITE_DATADOG_RUM_APPLICATION_ID &&
    VITE_DATADOG_RUM_CLIENT_TOKEN &&
    VITE_STAGE
  ) {
    datadogRum.init({
      applicationId: VITE_DATADOG_RUM_APPLICATION_ID,
      clientToken: VITE_DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'wx',
      env: VITE_STAGE,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
