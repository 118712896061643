import { Skeleton, SkeletonProps } from '@mui/material';
import cx from 'classnames';

interface MuiSkeleton extends SkeletonProps {
  /**
   * If true, adds extra classes to make the Skeleton behaves more like a normal div (no transform)
   */
  normalized?: boolean;
}

export default function MuiSkeleton({ normalized, ...props }: MuiSkeleton) {
  return (
    <Skeleton
      {...props}
      className={cx(
        {
          'shrink-0 grow-0 transform-none': normalized,
        },
        props.className,
      )}
    />
  );
}
