import { MuiTypography } from '@/components/mui/Typography';
import { useLogger } from '@/hooks/useLogger';
import { FormattedMessage } from 'react-intl';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import UnauthorizedPage from '../UnauthorizedPage';
import { AuthzMiddlewareError } from '@/models/auth/authz.schema';

export default function ErrorPage() {
  const { logError } = useLogger();
  const error = useRouteError();

  // The error type is initially `unknown` but we need to check for the presence of some fields (like `status` in ApiError)
  // We also can't use `instanceof ApiError` because there is not one global type for that - each ApiError type is linked to a specific service
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedError: any = error;

  function getErrorMessage() {
    if (isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    } else if (error instanceof Error) {
      if (untypedError?.status === 429) {
        return "We're experiencing a high volume of requests. Please try again later.";
      }
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else {
      if (error) {
        logError(error);
      }
      return '';
    }
  }

  const errorMessage = getErrorMessage();

  function getErrorComponent() {
    const errorStatus = untypedError?.status;

    if (errorStatus === 403) {
      if (untypedError?.body?.errors) {
        const isLocationsMismatchError = untypedError.body.errors.some(
          (error: { code?: string }) =>
            error.code === AuthzMiddlewareError.LocationsMismatch,
        );

        if (isLocationsMismatchError) {
          return <UnauthorizedPage roleRestricted />;
        }
      }

      return <UnauthorizedPage />;
    }

    return (
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
          <div className="mx-auto max-w-screen-sm text-center">
            <MuiTypography className="mb-4 text-6xl font-extrabold tracking-tight text-wx-primary">
              <FormattedMessage defaultMessage="Error" />
            </MuiTypography>
            <p className="mb-4 text-3xl font-medium tracking-tight text-wx-neutral-900 md:text-4xl">
              <FormattedMessage defaultMessage="An error occurred." />
            </p>
            <p className="mb-4 text-lg text-wx-neutral-500">
              <FormattedMessage defaultMessage="We apologize for the inconvenience." />
            </p>
            {errorMessage && (
              <pre className="whitespace-normal">{errorMessage}</pre>
            )}
            <Link
              to="/"
              className="bg-wx-primary-600 hover:bg-wx-primary-800 my-4 inline-flex rounded-lg px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4 focus:ring-wx-brand-300"
            >
              <FormattedMessage defaultMessage="Back to Homepage" />
            </Link>
          </div>
        </div>
      </section>
    );
  }

  return <>{getErrorComponent()}</>;
}
